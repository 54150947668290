/* https://www.photowall.es/doughboys-papel-pintado */
/* https://codepen.io/unetic/pen/QrbbWR */
.c-carousel {
  overflow:hidden;
  width:100%;
  
  article {
    border:5px solid #444;
    display:inline-block;
    padding:0 0px 30px 0px;
    text-align: center;
    margin:0 30px 20px;
    box-shadow:10px 10px 10px #444;
    padding-bottom:30px;
    figcaption {
      max-width: 200px;
      margin:0 auto;
      p {
        margin-bottom: 2em;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;  
        overflow: hidden;
      }
    }
  }
}