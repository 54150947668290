.header {
  .header__top {
    background-color:#444;
    i {
      svg {
        width:10px;
      }
    }
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .header__middle {

  }
  .header__bottom {
    // box-shadow: 0px 6px 5px #888;
    background-color:#daca09;
    // overflow:visible;
    @include desktop {
      // overflow:hidden
    }
  }
  .header__logo {
    position:relative;
    img {
      width:225px;
      max-width: 100%;
      margin-top:6px;
      margin-bottom:6px;
    }
  }
  .header__cart ,
  .header__user
  {
    svg {
      width:30px;
    }
    span {
      background-color:#daca09;
      color:#000;
      border:1px solid #000;
      width:30px;
      height:30px;
      line-height: 26px;
      text-align:center;
      border-radius:50%;
      font-weight:bold;
      font-size: .9em;
      top:-1.2em;
      right:-1.7em;
    }
  }
  .header__cart:hover ,
  .header__user:hover ,
  #body.body--page-comanda & .header__cart 

  {
    svg {
      path {
        fill: darken(#daca09,10);
      }
    }
  }
  @include mobile {
    .header__cart ,
    .header__user
    {
      svg {
        width:20px;
      }
      span {
        background-color:#222;
        color:#FFF;
        width:30px;
        height:30px;
        line-height: 30px;
        text-align:center;
        border-radius:50%;
        font-weight:bold;
        font-size: .9em;
        top:-1.2em;
        right:-1.7em;
      }
    }
  }
}