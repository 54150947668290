/* Plus and minus */
.qty-minus ,
.qty-plus
{
  @include reset;
  cursor:pointer;
  max-width: auto !important;
  // padding:4px;
  text-align:center;
  // min-width:20px;
  border-radius:50%;
  width:20px;
  height:20px;
  position:relative;
  display:inline-block !important;
  border:1px solid #CCC;
  line-height: 40%;
  vertical-align:middle;
  font-size:1em;


  
}
input[type="number"].qty {
    -moz-appearance: textfield;
}
input.qty {
  display:inline-block !important;
  width:40px !important;
  height:40px;
  line-height: 40px;
  text-align: center;
  
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;

  }

}




.e-wh-40px {
  width:40px;
  height:40px;
}
.e-show-password-input {
  position:absolute;
  width:30px;
  height:30px;
  display:block;
  cursor:pointer;
  // background:red;
  top:28px;
  right:4px;
  &:before {
    font-size:1.1em;
    line-height:30px;
  }
}

.e-background-middle-color {
  position:relative;
  &::before {
    content:("");
    position:absolute;
    background-color:$branding_gray--light;
    width:100%;
    height:60%;
    z-index:-1;
    top:20px;
  }
  &--gray-light {
    &::before {
      background-color:#f8f8f8;
    }
  }
}
.e-no-resize {
  resize: none;
}
.e-title {
  font-size:2rem;
  margin:0 0 .8em 0;
  padding:0;
}

.e-no-radius {
  border-radius:0 !important;
}
.e-btn-link {
  background-color:#e5e5e5;
  text-decoration:none;
  padding:4px 6px;
  border-radius:12px;
  margin:4px 0;
  display:inline-block;
  box-shadow:0px 1px 2px #777;
  transition:.2s;
  transform:translateY(0);
  &:hover {
    background-color:#dedede;
    box-shadow:0px 2px 2px #333;
    transform:translateY(1px);
  }
}
.e-btn {
  background-color:$branding_yellow;
  text-decoration:none;
  color:#000;
  font-weight:bold;
  padding:12px 20px;
  border-radius:0px;
  box-shadow:0px 5px 2px #555;
  position:relative;
  border:0;
  cursor:pointer;
  box-sizing:border-box;
  &::after {
    content:(">");
    margin-left:.5em;
    transition:.2s;
  }
  &:hover {
    transform:scale(.99);
    background-color:$branding_black;
    color:#FFF;
    &::after {
      margin-left:1em;

    }
  }
  &--no-arrow {
    &::after ,
    &::after 
    {
      display:none;
    }
  }
  &--transparent {
    background-color:transparent;
    color:#222;
    font-weight: 500;
    border:1px solid #666;
    box-sizing:border-box;
    display:inline-block;
  }
  &--big-padding {
    padding-right:40px;
    padding-left:40px;
  }
  &--square {
    border-radius:0;
  }
  &--ico-cart {
    text-indent: 30px;
    background-image:url($path + 'assets/svg/cart--white.svg');
    background-repeat:no-repeat;
    background-position : 10px center;
    background-size:24px 24px;
  }
}

.e-hr-theme {
  border:0;
  display:block;
  width:100%;
  height:2px;
  background-color:rgba(#EAEAEA,.8);
}



// Effect Oscure Banner element
.e-oscure-banner {
	position:relative;
	img {
		display: block;
	}
	&:before {
		content:("");
		background-color:rgba(#000,.25);
		width: 100%;
		height:100%;
		left:0;
		top:0;
		position: absolute;
		transition:1s;
	}
	&--opacity-50  {
		&:before {
			background-color:rgba(#000,.50);
		}
	}
}


// Order
.e-order {
	&-5 {
		order:5;
	}
}


// E-CLEAR
.e-clear  {
	clear:both;
	&:after {
		content: "";
		display: block; 
		clear: both;
	}
}
@include mobile {
	.e-clear-mobile {
		clear:both;
	}
}

// Alture Height , Widts 
.e-height-100 {
	height:100%;
}
.e-height-px {
  &-50 {
    height: 50px;
    max-height: 50px;
  }
  &-60 {
    height: 60px;
    max-height: 60px;
  }
  &-70 {
    height: 70px;
    max-height: 70px;
  }
  &-120 {
    height: 120px;
    max-height: 120px;
  }
}
// SHADOW
.e-shadow {
	margin-top: 100px;
	width:100%;
	height:100px;
	display:block;
	box-shadow: 0 -30px 30px 0px #E5E5E5;

}


// IMAGE
.e-image-center {
	display: block;
	margin:0 auto;
	&--mobile {
		@include mobile {
			display: block !important;
			margin:0 auto !important;
		}
	}
	&--desktop {
		@include desktop {
			display: block !important;
			margin:0 auto !important;
		}
	}
}
.e-image-float-left {
	float:left;
	padding-right:20px;
}
.e-image-float-right {
	float:right;
	padding-left:20px;
}



// IMAGE RADIUS
.e-image-radius-50 {
	border-radius:50%;
}

//CONTENT CENTER
@include mobile {
	.e-content-center {
		&__flex--only-mobile {
			display: flex;
			width: 100%;
			justify-content: center;
		}
	}
}


// LINE_HEIGHT
.e-text-line-height-em {
  &-1-2 {
    line-height:1.2em !important;
  }
}


// TEXT
.e-text-decoration {
	&-none {
		text-decoration:none;
	}
	&-underline {
		text-decoration:underline;
		&--hover {
			&:hover {
				text-decoration:underline;
			}
		}
	}
}
.e-text-align-right {
	text-align: right;
	&--mobile {
		@include mobile {
			text-align: right !important;
		}
	}
}
.e-text-align-left {
	text-align: left;
}

.e-text-transform {
	&-uppercase {
		text-transform:uppercase
	}
}

.e-text-color-alert , .e-color-alert {
	color: red;
}
.e-text-color-white  {
	color:#FFF !important;
	&--mobile {
		color:#FFF !important;
	}
}
.e-text-color-gray {
	color:$branding_gray !important;
	&--light {
		color:$branding_gray--light;
	}
}
.e-text-color-red {
	color:$branding_red !important;
}
.e-text-color-yellow {
  color:darken($branding_yellow,20);
}
.e-text-color-blue {
	color:$branding_blue !important;
}
.e-text-light-300 {
	font-weight: 300 !important;
}
.e-text-normal-400 {
	font-weight: 400 !important;
}
.e-text-medium-500 {
	font-weight: 500 !important;
}
.e-text-medium-700 {
	font-weight: 700 !important;
}


.e-text-color-black--only-mobile {
	@include mobile {
		color:$branding_black !important;
	}
}
.e-text-color-gray--only-mobile {
	@include mobile {
		color:$branding_gray !important;
	}
}
.e-text-color-blue--only-mobile {
	@include mobile {
		color:$branding_blue !important;
	}
}


.e-text-bold {
	font-weight: bold;
}
.e-text-semi-bold {
	font-weight: 500;
}


.e-text-align-center {
	text-align: center;
	@include mobile {
		&--mobile {
			text-align: center !important;
		}
	}
}

.e-text-align-inherit {
	text-align:inherit;
	@include mobile {
		&--mobile {
			text-align:inherit;
		}
	}
}

.e-text-justify {
	text-align: justify;
}

.e-text-left--mobile ,
.e-text-align-left--mobile 
{
	@include mobile {
		text-align: left;
	}
}




.e-color-branding-txt {
  color:$branding_red !important;
}
.e-color-branding-txt--oscure {
  color:$branding_red_2; 
}
.e-color-branding-txt--white {
  color:$branding_white;
}
.e-color-branding-txt--black {
  color:$branding_black !important;
}
.e-color-green {
  color:$branding_green;
}
.e-color-blue {
  color:$branding_blue;
}

/* E-BACKGROUND */
.e-no-background {
  background:none !important;
}

.e-background-branding-green {
  background-color: $branding_green;
}
.e-background-branding-blue {
  background-color: $branding_blue;
}

.e-background-branding-white {
  background-color: $branding_white;
}
.e-background-branding-gray {
  background-color: $branding_gray;
}
.e-background-branding-gray--light {
  background-color: $branding_gray--light;
}

.e-background-white {
  background-color: #FFF;
}
.e-background-black {
  background-color: $branding_black;
}

.e-background-branding-blue-2 {
  background-color: $branding_blue_2;
}

.e-background-branding-yellow {
  background-color: $branding_yellow;
}

.e-background-branding-lilac {
  background-color: $branding_lilac;
}

// e-style-number-theme;
.e-style-number-theme {
  margin:0;
  padding:.5em;
  counter-reset: myOrderedListItemsCounter;
  li {
    margin:0 0 1.6em 0;
    list-style: none;
    margin-left: 2em;
    position: relative;

    &:before {
      counter-increment: myOrderedListItemsCounter;
      content: counter(myOrderedListItemsCounter); 
      // content: counter(myOrderedListItemsCounter,decimal-leading-zero); 

      color: red;
      display: inline-block; 
      width: 2em;
      height:2em;
      line-height: 1.7em;
      margin-left: -1.8em;
      margin-right: .5em;
      background-color:transparent;
      text-align: center;
      color:#FFF;
      border-radius:50%;
      font-size: .7em;
      position:absolute;
      left:-1em;
      font-weight:bold;
      border:2px solid $branding_white;
    }
  }
}


// E-play-video
.e-play-video {
	a {
		display:block;
		&::after {
			content:("");
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			background:url('assets/svg/boton-de-play.svg') no-repeat center center;
			background-size:50% 50%;
			z-index:2
		}
		img {
			display:block;
			filter: brightness(.5) invert(0) grayscale(0);
			transition:.5s;
		}
		&:hover {
			img {
				filter: brightness(1) invert(0) grayscale(0);
			}
		}
	}
}

// special font 
.e-font-adami {
	font-family: 'Adamina', serif;
}
.e-font-cabin {
  font-family: 'Cabin',sans-serif;
}

.e-bb-0 {
	border:0 !important;
}
.e-color-fill-white {
  svg path {
    fill:#FFF;
  }
}

.e-background-c-blue {
	background-color:$branding_blue2;
}


// e-only
.e-only-900px {
	display:none;
	@media screen and (max-width: 900px) {
		display:block;
	}
}

.e-only-desktop {
	display: none;
	@include desktop {
		display: block !important;
	}
}
.e-only-tablet {
	display: none;
	@include tablet {
		display: block !important;
	}
}
.e-only-mobile {
	display: none;
	@include mobile {
		display: block !important;
	}
}




// BLOCKS : DISPLAY : FLEX
.e-display {
	&-block {
		display:block !important;
    &--mobile {
      @include mobile {
        display:block !important;
      }
    }
    &--mobile-xs {
      @include mobile-xs {
        display:block !important;
      }
    }
  }
  &-initial {
    display:initial !important;
  }
  &-inline-block {
    display:inline-block !important;
  }
}
.e-display-flex {
	@include flexbox();
	&--mobile {
		@include flexbox();
	}
}
.e-display-inline-block{
	display: inline-block !important;
}
.e-display-none {
	display:none !important;
  &--desktop {
    @include desktop {
      display:none !important;
    }
  }
  &--mobile {
    @include mobile {
     display:none !important;
   }
 }
 &--mobile-xs {
  @include mobile-xs {
    display:none !important;
  }
}
}
.e-flex-wrap {
	@include flex-wrap(wrap);
}
.e-position-relative {
	position:relative;
}
.e-flex-direction 
{
	&-column {
		flex-direction: column;
	}
	&-row {
		flex-direction: row;
	}
}
.e-justify-content {
	&-center {
		justify-content: center;
		&--mobile {
			@include mobile {
				justify-content: center;
			}
		}
	}
	&-flex-end {
		justify-content: flex-end;
	}
	&-flex-start {
		justify-content: flex-start;
	}
	&-space-between {
		justify-content: space-between;
	}
	&-space-around {
		justify-content: space-around;
	}
}
.e-flex-direction {
	&-column {
		flex-direction: column;
	}
}
.e-flex {
	&-1 {
		flex:1;
	}
}
.e-float {

	&-right {
		float:right;
		@include mobile {
			&--mobile {
				float:right !important
			}
		}
	}

	&-left {
		float:left;
		@include mobile {
			&--mobile {

				float:left !important
			}
		}
	}
	&-none {
		float:none;
		@include mobile {
			&--mobile {

				float:none !important
			}
		}
	}
}






// E-FILL
.e-fill-white {
	svg {
		fill:#FFF;
	}
}

// E-MIN E-MAX
.e-min-height-px-150 {
  min-height: 150px;
}
.e-min-height-px-120 {
  min-height: 120px;
}
.e-min-height-px-270 {
	min-height: 270px;
}
.e-min-height-px-600 {
  min-height: 600px;
  &-desktop {
   @media screen and (min-width: $mobile) {
    min-height: 600px;
  } 
}
}
.e-max-height-px-270 {
	max-height: 270px;
}
.e-max-height-px-100 {
  max-height: 100px;
}
.e-max-height-px-150 {
  max-height: 150px;
}
.e-min-height-px-150--mobile {
	@include mobile {
		min-height: 150px;
	}
}
.e-min-height-px-300--mobile {
  @include mobile {
    min-height: 300px;
  }
}


.e-dl-number {
	list-style:decimal;
	dt {
		display:list-item;
		list-style-type: decimal;
		list-style-position: inside;
	}
}



.e-color-blue {
	color:$branding_blue2;
}


.e-link-cool {
	border-bottom: 1px solid #CCC;
	display: inline-block;
	line-height: 0.85;
	text-shadow:
	2px 2px white,
	2px -2px white,
	-2px 2px white,
	-2px -2px white;
}


// E-CURSOR
.e-cursor-pointer {
	cursor:pointer !important;
}
.e-cursor-default {
	cursor:default !important;
}



// SUMMARY
.e-summary {
	details
	{
		transition:3s;
		min-height: 30px;
		border-bottom:1px solid #EFEFEF;
		line-height: 30px;
		margin-top:10px;
		padding:20px 0;
		transform:translateY(20px);
		border:0;
		outline:none;
		:focus {
			outline: 0;

		}
		&[open]{
			summary {
				&:before {
					content: "-"; /* the new icon */
					
				}
			}
		}
		summary {
			cursor:pointer;
			border-bottom:1px solid #111;
			display:flex;
			background:none;
			position:relative;
			&:hover {
				border-bottom:2px solid #111;
			}
			&::-webkit-details-marker {
				display: none;
			}
			&:before {
				content: "+"; /* the new icon */
				color: #111;
				margin-right: 5px;
				font-weight:bold;
				font-size: 45px;
				transform:translate(-24px , calc(50% - 50px));
				outline:0;
				position:absolute;
				right:50%;
				left:50%;
				border:4px solid #FFF;
				box-shadow:inset 0px 0px 1px 1px #111;
				width:40px;
				height:40px;
				line-height:40px;
				text-align:center;
				background:#FFF;
				font-weight:400;
			}
			
			h2 {
				vertical-align:middle;
				margin:0;
				padding:0;
				width:calc(100% - 45px);
				position:absolute;
				width:100%;
				text-align:center;
				top:1.5em;
			}
		}
		p {
			margin:0;
			padding:.5em 0;
			margin-bottom: .8em;
		}
	}
}
details[open] summary ~ * {
	animation: sweep .5s ease-in-out;
}
@keyframes sweep {
	0%    {opacity: 0; transform:translateY(5px);}
	100%  {opacity: 1; }
}





// E-BORDER
.e-border {
	&-0 {
		border:0 !important;
	}
}
.e-border {
	&-right {
		border-right:1px solid #dcdcdc;
	}
	&-bottom {
		border-bottom:1px solid #dcdcdc;;
	}
	&--no-mobile {
		&-0 {
			@include mobile {
				border:0;
			}
		}
		&-right {
			@include mobile {
				border-right:0;
			}
		}
		&-left {
			@include mobile {
				border-left:0;
			}
		}
		&-top {
			@include mobile {
				border-top:0;
			}
		}
		&-bottom {
			@include mobile {
				border-bottom:0;
			}

		}
	}
	&-only-mobile {
		&-0 {
			@include mobile {
				border:0;
			}
		}
		&-right {
			@include mobile {
				border-right:1px solid #dcdcdc;;
			}
		}
		&-left {
			@include mobile {
				border-left:1px solid #dcdcdc;;
			}
		}
		&-top {
			@include mobile {
				border-top:1px solid #dcdcdc;;
			}
		}
		&-bottom {
			@include mobile {
				border-bottom:1px solid #dcdcdc;;
			}
		}
	}
  &-dotted {
    &-bottom {
      border-bottom:1px dotted;
    }
  }
}



// E-ICO : ICONS by SVG
.e-ico {
	font-size: 0;
	svg {
		width:24px;
		height:24px;
	}
	&--facebook {
	}
	&--instagram {
	}
	&--phone {
		svg {
			width:14px;
			height:14px;
			margin-right: 4px;
			transform: translateY(2px);
		}
	}
}
.e-icon {
	display: inline-block;
	vertical-align: middle;
	margin-left:1em;

}





// E-MESSAGE 
.e-message {
	text-align: center;
	font-size:1em;
	display:flex;
	flex-wrap:wrap;
	justify-content:center;
	flex-direction: column;
	padding:20px 10px;
	margin:30px 0;
	animation: fadeinMain-e-message 1.3s;
	@keyframes fadeinMain-e-message {
		from { opacity: 0; transform: translateY(100%)}
		to   { opacity: 1; transform: translateY(0%)}
	}
	h1 {
		font-weight: bold !important;
		position:relative;
	}

	p {
		// padding:0em 0 2em;
		// margin:auto;
	}

	&--ok {
		background-color:#008a00;
		color:#FFF;		
	}

	&--info {
		background-color:#465a97;
		color:#FFF;
	}
	&--error {
		background-color:#c6040e;
		color:#FFF;
	}
}
#body {


  .alert {
    @extend .e-message;
    @include reset_list;
    padding:0;
    margin:0;
  }
  .alert-danger {
    @extend .e-message--error;

    li {
      padding: 7px 5px;
      border-bottom: 8px solid #FFF;
      overflow: hidden;
    }
  }
  .alert-success {
    @extend .e-message--ok;
    padding: 7px 5px;
  }
  .alert-info {
    @extend .e-message--info;
    padding: 7px 5px;
  }
}