.c-single-product {
  a {
    img {
      transition: .1s;
      padding:8px;
    }
    &:hover {
      img {
        filter: blur(0px) grayscale(20%) sepia(2%) contrast(100%) brightness(102%);
      }
    }
  }
  .c-single-product__description {
    line-height: 1.2em;
    > p {
      margin-top:0;
    }
    div {
      margin-bottom:.7em;
    }
  }
  .c-single-product__figure {
    figure {
      border:3px solid transparent;
      position:relative;
      &:hover {
        border:3px solid #444;
        box-shadow:10px 10px 10px #444;
        // border:1px solid #000;
      }
      img {
        display: block;
        margin:0 auto;
        max-height: 575px;
        @include mobile {
          max-height: 50vh;
        }   
      }
      figcaption {
        i {
          svg , img 
          {
            position: absolute;
            top:-40px;
            left:-40px;
            width:80px;
            @include desktop {
              left:0;
              top:0;
              width:70px;
            }
            @include mobile-xs {
              left:0;
              top:0;
              width:50px;
            }
          }
        }
      }
    }
    &-gallery {
      width:100%;
      display:block;
      dl {
        width:100%;
        display:block;
        dt {
          width:31%;
          display:inline-block;
          margin:0 1.665% 0 1.665%;
          img {
            // width:100%;
            border:3px solid transparent;
            box-sizing: border-box;
            &:hover {
              border:3px solid #444;
              box-shadow:10px 10px 10px #444;
            }
          }
        }
      }
    }
  }

  .c-single-product__cart {
    label {
      cursor:pointer
    }
    input {
      & + p {
        width:50px;
        height:40px;
        border:1px solid #CCC;
        text-align:center;
        margin:0;
        padding:0;
        line-height:40px;
        font-weight: 500;
        transition:.1s;
      }
      &:hover ,
      &:checked 
      {
        & + p {
          background-color:$branding_yellow;
        }
      }
    }
    button[type="submit"]{
      width:350px;
      max-width:100%;
    }
  }
  #item-quantity {
    width:70px;
    height:40px;
    line-height:40px;
    margin-bottom: 1em;
    text-align:center;
  }
  .c-single-product__title {
    font-size: 24px;
    // margin-top: 1.8em;
  }
  .c-single-product__quantity {
    font-size: 16px;
  }
}