.c-pop-up-enter {
  display: none;
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    button {
      margin:2em .75em;
      @extend .e-btn, .e-btn--no-arrow;
    }
  }

  &__super {
    h2 {
      font-size: 3em;
      text-align: center;
      color:$branding_black;
      @include mobile {
        font-size: 2em;
      }
    }
  }

}