.footer {
  background-color:$branding_gray--light;
  color:#666;
  display:block;
  overflow:hidden;
  margin-top: 80px;
  .footer__nav {
    @include reset_list;
    ul {
      li {
        a {
          text-decoration:none;
          display: inline-block;
          padding:8px 4px;
          color:#666;
          font-weight: 500;
          &:hover {
            color:#000;
          }
        }
      }
    }
  }
  .footer__col {
    text-align: center;
    padding:50px 0;
    @include mobile-xs {
      padding:25px 0 0 0;
    }
    h4 {
      font-size: 1.3em;
      color:#000;
    }
  }
  .footer__bottom {
    background-color:#1D1D1D;
    text-align:center;
    color:#FFF;
    font-weight:500;
    overflow:hidden;
    padding:50px 20px;
    b {
      color:lighten($branding_yellow,7%); 
      font-weight:700;
    }
    a {
      border-bottom:1px dotted #444;
      &:hover {
        text-decoration:underline;
      }
    }
    &-legal {
      li {
        position:relative;
        &::after {
          content:("·");
          margin:0 .5em;
        }
        &:last-child {
          &::after {
            display:none;
          }
        }
      }
    }
  }
}