.c-menu {
  margin-bottom: .0em;
  // overflow:hidden;
  .item-icon {
    margin-left: .5em;
    position:absolute;
    &:before {
      background-color:darken(#daca09,20);
      border-radius:0%;
      font-size: .8em;
      width:16px;
      height:16px;
      line-height:16px;
      text-align:center;
      transform: translateY(0px);
      position:absolute;
      color:#FFF;
      border-bottom:2px solid darken(#daca09,30);
    }
  }
  .c-menu__menu-container {
    width: 100%;
  }
  .item-menu-mobile {
    display: none;
    padding: 20px;
    &:after {
      content: "\f394";
      font-family: "Ionicons";
      font-size: 2.5rem;
      padding: 0;
      float: right;
      position: relative;
      top: 33%;
      transform: translateY(-33%);
    }
  }
  .item-menu-dropdown-icon {
    &:before {
      content: "\f489";
      font-family: "Ionicons";
      display: none;
      cursor: pointer;
      float: right;
      padding: 1.5em 2em;
      background: #fff;
      color: #333;
    }
  }
  .c-menu__nav {
    font-size: .8em;
    > ul {
      margin: 0 auto;
      width: 100%;
      list-style: none;
      padding: 0;
      position: relative;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      display:flex;
      &::first-child {
        border:1px solid red;
      }
      &:before,
      &:after {
        content: "";
        display: table;
      }
      &:after {
        clear: both;
      }
      > li {
        float: left;
        // background: #e9e9e9;
        padding: 0;
        margin: 0;
        border-right:1px solid #666;
        &:last-child {
          border-right:0;
        }
        a {
          text-decoration: none;
          padding: 1.5em 2.8em 1.5em 2.8em;
          color:#000;
          font-weight:600;
          @media screen and (max-width: 1400px) {
            padding: 1.5em 2.5em 1.5em 1.5em;
          }
          display: block;
          &.item-center {
            padding: 1.5em 1.8em 1.5em 1.8em;
            @media screen and (max-width: 1230px) {
              padding: 1.5em 1em 1.5em 1em;
            }
          }
        }
        &.item-menu-dropdown-icon > a {
          padding-right:30px;
        }
        &:hover {
          background: #222;
          > a {
            color:#FFF;
          }
        }
        > ul {
          display: none;
          width: 100%;
          background: #222;
          padding: 20px;
          position: absolute;
          z-index: 99;
          left: 0;
          margin: 0;
          list-style: none;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          box-shadow:0px 24px 24px #666;
          border-radius:0 0 20px 20px;
          &:before,
          &:after {
            content: "";
            display: table;
          }
          &:after {
            clear: both;
          }
          > li {
            margin: 0;
            padding-bottom: 0;
            list-style: none;
            width: 25%;
            background: none;
            float: left;
            &.item-image {
              float: right;
            }
            a {
              color: #ccc;
              padding: .2em 0;
              width: 95%;
              display: block;
              border-bottom:2px solid #000;
              &:hover {
                color:#FFF;
              }
              img {
                @extend .dim;
              }
            }
            > ul {
              display: block;
              padding: 0;
              margin: 10px 0 0;
              list-style: none;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              &:before,
              &:after {
                content: "";
                display: table;
              }
              &:after {
                clear: both;
              }
              > li {
                float: left;
                width: 100%;
                padding: 10px 0;
                margin: 0;
                font-size: .8em;
                a {
                  border: 0;
                }
              }
            }
          }
          &.item-normal-sub {
            width: 300px;
            left: auto;
            padding: 10px 20px;
            > li {
              width: 100%;
                border-bottom:1px solid #000;
              a {
                border: 0;
                padding: .5em 0;
              }
            }
          }
        }
        @include mobile-xs {
          border-right:0;
          border-bottom:1px solid;
          border-top:1px solid;
        }
      }
    }
  }

  #body & .c-menu__search {
    padding-bottom: 16px;
    display:none;
    @include mobile-xs {
      display:block;
    }
  }


  @include desktop {
    .item-icon {
      display:none;
    }
    .menu-container {
      width: 100%;
    }
    .item-menu-mobile {
      display: block;
    }
    .item-menu-dropdown-icon {
      &:before {
        display: block;
      }
    }
    .c-menu__nav{
      > ul {
        display: none;
        > li {
          width: 100%;
          float: none;
          display: block;
          a {
            padding: 1.5em;
            width: 100%;
            display: block;
          }
          > ul {
            position: relative;
            &.normal-sub {
              width: 100%;
            }
            > li {
              float: none;
              width: 100%;
              margin-top: 20px;
              &:first-child {
                margin: 0;
              }
              > ul {
                position: relative;
                > li {
                  float: none;
                }
              }
            }
          }
        }
      }
      .item-show-on-mobile {
        display: block;
      }
    }
  }
}