.c-controls {
  @include reset_list;
  ol {
  border-bottom:2px solid rgba(#000,.1);
    display:flex;
    justify-content: center;
    margin:6em auto 3em;
    max-width:96%;
    @include mobile {
      margin:3em auto 2em;
    }
    li {
      padding:6px 18px;
    }
  }
  button {
    @include reset;
    font-weight:bold;
    cursor:pointer;
    color:#898989;
    font-size:1.5rem;
    padding:.2em .2em .1em .2em;
    transition:.1s all;
    border-bottom:5px solid #FFF;
    &:hover ,
    &.is-active 
    {
      color:#000;
    }
    &.is-active {
      border:3px dotted darken($branding_yellow,0);
      background-color:rgba($branding_yellow,.2);
    }
  }

  @include mobile {
    ol {
      li {
        padding:6px 10px;
      }
    }
    button {
      font-size:1em;
    }
  }
}