.c-product {
  font-weight:600;
  figure {
    position:relative;
    img {
      display:block;
      border-bottom:1px solid #FFF;
      display: block;
      border-bottom: 1px solid #FFF;
      height: 300px;
      margin: 0 auto;
      width: 100%;
      object-fit: contain;
    }
    figcaption {
      i {
        svg , img 
        {
          position: absolute;
          top:0px;
          left:0px;
          width:60px;
          border:0;
          height: 60px; // provisional
          // border: 2px solid red; // provisional
          // &::before {
            // content:("Icon?")
          // }
          @include desktop {
            left:0;
            top:0;
            width:50px;
          }
          @include mobile-xs {
            left:0;
            top:0;
            width:40px;
          }
        }
      }
    }
  }
  h2 {
    font-weight:700;
    margin:0 auto;
    padding:0 2em;
    font-size:1.1em;
    margin-top:1em;
    line-height:1.4em;
    height:2.9em;
    overflow:hidden;
    white-space: normal;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    @include tablet {
      font-size: 1em;
    }
    @include mobile {
      font-size:.9em;
    }
  }
  .c-product__container{
    text-decoration:none;
    display:block;
    border:5px solid #FFF;
    img {
      transition: .2s;
      padding:4px;
    }
    &:hover {
      border:5px solid #444;
      box-shadow:10px 10px 10px #444;
      figure >
      img {
        filter: blur(0px) grayscale(55%) sepia(0.4%) contrast(100%) brightness(103%);
        // border-bottom:1px solid #666;

      }
    }
  }
  .c-product__price {
    padding-bottom:.7em;
    display:block;
    p {
      margin:0;
      padding:4px 0;
    }
    &-item-quantity {
      &-type {
        display:inline-block;
        margin-left:.25em;
      }
    }
    &-item-price {
      color:darken($branding_yellow,10);
      font-weight:bold;
      transition: .1s color;
    }
  }
  .c-product__link {
    text-decoration:none;
    &:hover {
      .c-product__price-item-price {
        color:darken($branding_yellow,10);
      }
    }
  }
}