// Measures
$mobile 		: 767px; //768px = 48em x 16px (-1px)
$container 	: 1400px;

// Grid px  REV
$mobile_px 			: 500px;
$tablet_px		  : 800px;
$desktop_px     : 1024px; // 64em
$grid-container : $container;
$grid-super		  : 1920px;

$grid-mobile-theme : 1140px;

$path : "../../";

// breakpoint 
$breakpoint-not-small: 'screen and (min-width: 48em)' !default;
$breakpoint-medium: 'screen and (min-width: 48em) and (max-width: 64em)' !default;
$breakpoint-large: 'screen and (min-width: 64em)' !default;

// Fonts
$Cabin 		 	: 'Cabin', sans-serif;
$Raleway 	 	: 'Raleway', sans-serif;
$Multi 		 	: 'Muli', sans-serif;
$Roboto    	: 'Roboto', sans-serif;
$Montserrat	: 'Montserrat', sans-serif;
$BebasNeue 	: 'BebasNeue';
$SansPro	 	: 'Source Sans Pro', sans-serif;
$Assistant 	: 'Assistant', sans-serif;
$Quicksand 	: 'Quicksand', sans-serif;
$OpenSansCodensed  : 'Open Sans Condensed', sans-serif;
$YanoneKaffeesatz  : 'Yanone Kaffeesatz', sans-serif;
$DINCondensed-Bold :  DINCondensed-Bold;  // Font extern || No GoogleFonts
$CabinCondensed    : 'Cabin Condensed', sans-serif;
$Poppins    		   : 'Poppins', sans-serif;
$Oswald						 : 'Oswald', sans-serif;
$Bordonaro         :  Bordonaro;
$Arimo 						 : 'Arimo', sans-serif;
$Muli 						 : 'Muli', sans-serif;
$ShadowsIntoLight  : 'Shadows Into Light', cursive;
$SairaCondensed    : 'Saira Condensed', sans-serif;
$DroidSerif : 'Droid Serif',sans-serif;
$Cabin : 'Cabin',sans-serif;

// Colors
$branding_green--light: #CFE5D0;

$branding_green_txt	  : #2F9D54;
$branding_blue_txt 		: #08344C;

$branding_yellow			: #daca09;
$branding_pink				: #B83178;

$branding_brown 			: #A07F6F;
$branding_green_2     : #2DA836;

$branding_carn        : #CC6633;
$branding_carn-text   : #BFA280;

$branding_red_2   		: #8e000d;
$branding_lilac  			: #7A4871;
$branding_blue_2      : #17495D;

$branding_blue-green  : #16AEA6;
$branding_green_3     : #8DA826;
$branding_green_4     : #5EBAA4;

$branding_orange 			: #FFA34C;
$branding_orange_2  	: #F08019;
$branding_orange--light : #F1A24D;

$branding_beige			  : #E9E5DA;

$branding_green	    	: #d1d300; 
$branding_blue        : #102134;

// Experimental
// background-blend-mode: multiply;  Images TEST >>> https://pixabay.com/

$branding_blue2       : #0092D8;
$branding_white 			: #F3F3F3;
$branding_gray--darken: #444444;
$branding_gray--light2: #E9E9E9; // Footer
$branding_gray--light3: #E3E3E3; // lines menu
$branding_gray--text  : gray;


// RUN COLORS THEME     #AE1031; #000000; gray ; #D6D6D6
$branding_red 				: #AE1031;  
$branding_black				: #000000;
$branding_gray				: gray;
$branding_gray--light : #D6D6D6;
// END COLORS THEME



// Fonts Theme
// $font-thin-theme       : 100;
// $font-extralight-theme : 200;
$font-light-theme      : 300;
$font-regular-theme    : 400;
$font-medium-theme     : 500;
$font-semibold-theme   : 600;
$font-bold-theme       : 700;
// $font-extrabold-theme  : 800;
// $font-black-theme      : 900;
// END Fonts Theme
